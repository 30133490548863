(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-manager/assets/javascripts/draw-manager-content.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-manager/assets/javascripts/draw-manager-content.js');
"use strict";


const {
  useEffect
} = React;
const Paper = svs.ui.ReactPaper;
const {
  ScrollAwareTopOverlay
} = svs.components.lbUi.scrollAwareTopOverlay;
const {
  DrawCardContainer
} = svs.components.tipsen.drawCard;
const {
  useCountdown
} = svs.components.poolBetting.hooks;
const {
  DrawSelectorTable
} = svs.components.tipsen.drawSelector;
const {
  VisibilityWrapper
} = svs.components.lbUi.visibilityWrapper;
const {
  DRAW_CARD_VARIANTS
} = svs.components.tipsen.drawCard.constants;
const DrawManagerContent = _ref => {
  let {
    isMediumDevice,
    toggleDrawSelector,
    hasExtraMoneyValue,
    productBranding,
    shouldEnableDrawSelector,
    isDrawSelectorVisible,
    draws,
    currentDraw,
    sportTypes
  } = _ref;
  const drawManagerRef = React.useRef();

  useEffect(() => {
    const element = drawManagerRef.current;
    element === null || element === void 0 || element.addEventListener('tipsenOpenDrawSelector', toggleDrawSelector);
    return () => element === null || element === void 0 ? void 0 : element.removeEventListener('tipsenOpenDrawSelector', toggleDrawSelector);
  }, [toggleDrawSelector]);
  const countdownString = useCountdown({
    targetDateTime: currentDraw.regCloseTime
  });
  return React.createElement("div", {
    className: "pg_draw_manager",
    ref: drawManagerRef
  }, !isMediumDevice && React.createElement(ScrollAwareTopOverlay, {
    intersectionElementSelector: ".pg_draw_manager__current_draw_card",
    intersectionThreshold: 0.1,
    onTopOverlayWrapperClick: () => draws.length > 1 && toggleDrawSelector(),
    showTopOverlay: Boolean(countdownString)
  }, React.createElement(Paper, {
    branding: "sport",
    className: "elevation-400"
  }, React.createElement(DrawCardContainer, {
    branding: productBranding,
    countdownString: countdownString,
    currentDraw: currentDraw,
    drawCardVariant: DRAW_CARD_VARIANTS.MINI
  }))), React.createElement(Paper, {
    branding: "sport",
    className: "pg_draw_manager__current_draw_card".concat(isDrawSelectorVisible ? ' pg_draw_manager__current_draw_card--hidden' : '')
  }, React.createElement(DrawCardContainer, {
    branding: productBranding,
    currentDraw: currentDraw,
    drawCardVariant: isMediumDevice ? DRAW_CARD_VARIANTS.LARGE : DRAW_CARD_VARIANTS.SMALL,
    onToggleDrawSelector: toggleDrawSelector,
    shouldShowChangeDrawButton: shouldEnableDrawSelector
  })), shouldEnableDrawSelector && React.createElement(VisibilityWrapper, {
    className: "pg_draw_manager__draw_selector",
    isVisible: isDrawSelectorVisible,
    useOpacityTransition: true
  }, React.createElement(Paper, {
    branding: "sport"
  }, React.createElement(DrawSelectorTable, {
    branding: productBranding,
    draws: draws,
    hasExtraMoneyValue: hasExtraMoneyValue,
    onToggleDrawSelector: toggleDrawSelector,
    selectedDrawNumber: currentDraw.drawNumber,
    sportTypes: sportTypes
  }))));
};
setGlobal('svs.components.tipsen.drawManager.DrawManagerContent', DrawManagerContent);

 })(window);