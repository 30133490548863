(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-manager/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-manager/assets/javascripts/utils.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  useMemo,
  useState,
  useEffect
} = React;
const {
  DRAW_SORT_FLOW,
  DRAW_SORT_QS,
  DRAW_FILTER_QS
} = svs.components.tipsen.drawSelector.constants;
const {
  getFundInfoToShow
} = svs.components.tipsen.drawCard.utils;
const {
  SportTypeToString
} = svs.components.sport.sportTypes;
const {
  productIds
} = svs.utils.products;
const useHandleSortDraws = drawsData => {
  var _drawsData$;
  const qs = new URLSearchParams(window.location.search);
  const sortFlowFromUrl = qs.get(DRAW_SORT_QS);
  const [sortFlow, setSortFlow] = useState(sortFlowFromUrl);
  useEffect(() => {
    setSortFlow(sortFlowFromUrl);
  }, [sortFlowFromUrl]);
  const isMatchen = (drawsData === null || drawsData === void 0 || (_drawsData$ = drawsData[0]) === null || _drawsData$ === void 0 ? void 0 : _drawsData$.productId) === productIds.MATCHEN;
  const sortedDrawsData = useMemo(() => {
    if (!sortFlow && isMatchen) {
      setSortFlow(DRAW_SORT_FLOW.NET_SALE.value);
    }
    if (!sortFlow || drawsData.length < 2) {
      return drawsData;
    }
    const sortedDraws = drawsData.sort((a, b) => {
      if (sortFlow === DRAW_SORT_FLOW.REG_CLOSE_TIME.value) {
        const aDate = new Date(a.regCloseTime).getTime();
        const bDate = new Date(b.regCloseTime).getTime();
        if (aDate === bDate) {
          return 0;
        }
        if (aDate > bDate) {
          return 1;
        }
        if (aDate < bDate) {
          return -1;
        }
      }
      if (sortFlow === DRAW_SORT_FLOW.NET_SALE.value) {
        const aNetSale = parseInt(a.currentNetSale, 10);
        const bNetSale = parseInt(b.currentNetSale, 10);
        if (aNetSale === bNetSale) {
          return 0;
        }
        if (aNetSale > bNetSale) {
          return -1;
        }
        if (aNetSale < bNetSale) {
          return 1;
        }
      }
      if (sortFlow === DRAW_SORT_FLOW.EXTRA_MONEY.value) {
        const aExtraMoney = Object.keys(a.extraMoney);
        const bExtraMoney = Object.keys(b.extraMoney);
        if (!aExtraMoney.length && !bExtraMoney.length) {
          return 0;
        }
        if (aExtraMoney.length && !bExtraMoney.length) {
          return -1;
        }
        if (bExtraMoney.length && !aExtraMoney.length) {
          return 1;
        }
        return parseInt(b.extraMoney.amount, 10) - parseInt(a.extraMoney.amount, 10);
      }
      return 0;
    });
    return sortedDraws;
  }, [drawsData, isMatchen, sortFlow]);
  return sortedDrawsData;
};
const useFilterDraws = drawsData => {
  const qs = new URLSearchParams(window.location.search);
  const filterFromUrl = qs.getAll(DRAW_FILTER_QS).toString();
  const [filter, setFilter] = useState([]);
  useEffect(() => {
    const values = filterFromUrl.split(',');
    setFilter(values.filter(value => Boolean(value)));
  }, [filterFromUrl]);
  const amountOfSports = useMemo(() => {
    const sports = new Set();
    drawsData.forEach(draw => {
      sports.add(draw.sportType);
    });
    return sports.size;
  }, [drawsData]);
  const filteredDrawsData = useMemo(() => {
    if (amountOfSports <= 1 || !filter || !filter.length) {
      return drawsData;
    }
    const filteredData = drawsData.filter(draw => filter.includes(SportTypeToString[draw.sportType]));
    return filteredData.length ? filteredData : drawsData;
  }, [filter, drawsData, amountOfSports]);
  return filteredDrawsData;
};
const composeDrawWithFunds = (draws, allFunds) => {
  const composedDraws = draws.map(draw => {
    const fundData = allFunds.find(fund => fund.drawNumber === draw.drawNumber);
    const extraMoney = getFundInfoToShow(fundData);
    return _objectSpread(_objectSpread({}, draw), {}, {
      extraMoney
    });
  });
  return composedDraws;
};
setGlobal('svs.components.tipsen.drawManager.utils', {
  useHandleSortDraws,
  composeDrawWithFunds,
  useFilterDraws
});

 })(window);